import VueI18n from 'vue-i18n'
import dictionary from '@/plugins/i18n'
const i18n = new VueI18n(dictionary)

const moment = require('moment')

export default {
    filters: {
        capitalized: value => {
            if (!value) return value
            let capitalized = []
            value.split(' ').forEach(word => {
                capitalized.push(
                    word.charAt(0).toUpperCase() +
                    word.slice(1).toLowerCase()
                )
            })
            return capitalized.join(' ')
        }
    },
    timezoneMask: {
        mask: 'UTCS##:##',
        tokens: {
            '#': { pattern: /\d/ },
            'S': { pattern: /[+-]/ }
        }
    },
    chassisMask: {
        mask: 'XXXXXXXXXXXXXXXXX',
        tokens: {
            'X': {
                pattern: /[0-9A-Z]/,
                transform: v => v.toLocaleUpperCase(),
            }
        }
    },
    alphanumericMask: {
        mask: ['X', 'XX', 'XXX', 'XXXX', 'XXXXX', 'XXXXXX', 'XXXXXXX', 'XXXXXXXX', 'XXXXXXXXX', 'XXXXXXXXXX', 'XXXXXXXXXXX', 'XXXXXXXXXXXX', 'XXXXXXXXXXXXX', 'XXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXXXXX'],
        tokens: {
            'X': {
                pattern: /[0-9A-Za-z]/,
                transform: v => v.toLocaleUpperCase(),
            }
        }
    },
    motorMask: {
        mask: ['XXXXXXXXX', 'XXXXXXXXXX', 'XXXXXXXXXXX', 'XXXXXXXXXXXX', 'XXXXXXXXXXXXX', 'XXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXXXXX', 'XXXXXXXXXXXXXXXXXXXXX'],
        tokens: {
            'X': {
                pattern: /[0-9A-Z]/,
                transform: v => v.toLocaleUpperCase(),
            }
        }
    },
    required: value => !!value || i18n.t('Obrigatório.'),
    min: (size) => (value) => (value && value.length >= size) || i18n.t(`Pelo menos ${size} caracteres`),
    range: (min, max) => (value) => !value || (value >= min && value <= max) || i18n.t(`Fora da faixa ${min} ~ ${max}`),
    email: value => {
        // /.{1,}@.{2,}\..{2,}/
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !value || pattern.test(value) || i18n.t('Email inválido.')
    },
    cellphone: value => {
        const pattern = /^\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}$/
        return !value || pattern.test(value) || `${i18n.t("Telefone inválido")} ${i18n.t("Ex.")}: (00) 91234-1234.`
    },
    phone: value => {
        const pattern1 = /^\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}$/
        const pattern2 = /^[0-9]{4}\s[0-9]{3}\s[0-9]{4}$/
        return !value || pattern1.test(value) || pattern2.test(value) || `${i18n.t("Telefone inválido")} ${i18n.t("Ex.")}: (00) 91234-1234 ${i18n.t("ou")} (00) 1234-1234 ${i18n.t("ou")} 0800 123 1234.`
    },
    alphanumeric: value => {
        const pattern = /^[0-9A-Z]{1,20}$/
        return !value || pattern.test(value) || i18n.t('Campo alfanumérico de 1 até 20 dígitos.')
    },
    cep: value => {
        //'#####-###'
        const pattern = /^\d{5}-\d{3}$/
        return !value || pattern.test(value) || i18n.t('CEP inválido.')
    },
    money: value => {
        const pattern = /^\d{1,3}.\d{2}$/
        return !value || pattern.test(value) || `${i18n.t("Valor inválido")}. ${i18n.t("Ex.")}: 0.50`
    },
    time: value => {
        const pattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
        return !value || pattern.test(value) || `${i18n.t("Valor inválido")}. ${i18n.t("Ex.")}: 00:00 ~ 23:59`
    },
    date: value => {
        const pattern = /^[0-3][0-9]\/[0-1][0-9]\/[1-2][0-9][0-9][0-9]$/
        return !value || pattern.test(value) || `${i18n.t("Valor inválido")}. ${i18n.t("Ex.")}: 31/12/2022`
    },
    rangeDate: (valueFormat, min, max) => (value) => !value || (moment(value, valueFormat).isSameOrAfter(min, 'day') && moment(value, valueFormat).isSameOrBefore(max, 'day')) || i18n.t(`Fora da faixa ${min.format(valueFormat)} ~ ${max.format(valueFormat)}`),
    dateTime: value => {
        const pattern = /^[0-3][0-9]\/[0-1][0-9]\/20[0-9][0-9] ([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
        return !value || pattern.test(value) || `${i18n.t("Valor inválido")}. ${i18n.t("Ex.")}: 31/12/2022 23:59`
    },
    timezone: value => {
        const pattern = /^UTC[-+]{1}\d{2}:\d{2}$/
        return !value || pattern.test(value) || `${i18n.t("Valor inválido")}, ${i18n.t("Ex.")}: UTC-03:00`
    },
    cpfValidatorDigit: value => {
        if (!value) {
            return true;
        }
        let ok = false;
        let cpf = value.replace(/[\s.-]*/igm, '')
        if (
            cpf &&
            cpf.length == 11 &&
            !(cpf == "00000000000" ||
                cpf == "11111111111" ||
                cpf == "22222222222" ||
                cpf == "33333333333" ||
                cpf == "44444444444" ||
                cpf == "55555555555" ||
                cpf == "66666666666" ||
                cpf == "77777777777" ||
                cpf == "88888888888" ||
                cpf == "99999999999")
        ) {
            let soma = 0;
            let resto;
            for (let i = 1; i <= 9; i++) {
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
            }

            resto = (soma * 10) % 11;
            if ((resto == 10) || (resto == 11)) resto = 0;
            if (resto == parseInt(cpf.substring(9, 10))) {

                let soma = 0;
                for (let i = 1; i <= 10; i++) {
                    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
                }
                resto = (soma * 10) % 11;
                if ((resto == 10) || (resto == 11)) {
                    resto = 0;
                }
                if (resto == parseInt(cpf.substring(10, 11))) {
                    ok = true;
                }
            }
        }
        return ok || i18n.t('Erro ao checar CPF.')
    },
    cnpjValidatorDigit: value => {
        if (!value) {
            return true;
        }
        // https://www.geradorcnpj.com/
        let ok = false;
        let cnpj = value.replace(/[^\d]+/g, '');
        if (cnpj != '' && cnpj.length == 14 &&
            !(
                cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999"
            )) {
            // Valida DVs
            let tamanho = cnpj.length - 2;
            let numeros = cnpj.substring(0, tamanho);
            let digitos = cnpj.substring(tamanho);
            let soma = 0;
            let pos = tamanho - 7;
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado == digitos.charAt(0)) {
                tamanho = tamanho + 1;
                numeros = cnpj.substring(0, tamanho);
                soma = 0;
                pos = tamanho - 7;
                for (let i = tamanho; i >= 1; i--) {
                    soma += numeros.charAt(tamanho - i) * pos--;
                    if (pos < 2) {
                        pos = 9;
                    }
                }
                resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
                if (resultado == digitos.charAt(1)) {
                    ok = true;
                }
            }
        }
        return ok || i18n.t('Erro ao checar CNPJ.')
    },
    cnpj: value => {
        //'##.###.###/####-##'
        const pattern = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/
        return !value || pattern.test(value) || i18n.t('CNPJ inválido.')
    },
    coordinate: value => {
        // '-##.########, -##.########'
        const pattern = /^-?\d{1,3}.\d{5,14}, ?-?\d{1,3}.\d{5,14}$/
        return !value || pattern.test(value) || i18n.t('Coordenadas inválidas. Coloque de 5 a 14 dígitos de precisão')
    },
    password: value => {
        var strength = 0;
        if (value && value.length > 5) {
            if (value.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
                strength += 1;
            }
            if (value.match(/([a-zA-Z])/) && value.match(/([0-9])/)) {
                strength += 1;
            }
            if (value.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
                strength += 1;
            }
            if (value.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,",%,&,@,#,$,^,*,?,_,~])/)) {
                strength += 1;
            }
        }
        return strength >= 2 || i18n.t("Escolha uma senha de 6 a 15 caracteres, contendo números, símbolos, letras maiúsculas e minúsculas.")
    },
    brazilianPlate: value => {
        const pattern = /^[A-Z]{3}[0-9]{1}[0-9A-Z]{1}[0-9]{2}$/
        return !value || pattern.test(value) || `${i18n.t('Placa fora do padrão.')}. ${i18n.t("Ex.")}: AAA1111 - BBB1C222`
    },
    driverLicense: value => {
        const pattern = /^[0-9]{11}$/
        return !value || pattern.test(value) || `${i18n.t('Número da CNH fora do padrão. Deve conter 11 dígitos numericos.')}`
    },
    chassis: value => {
        const pattern = /^[0-9A-Z]{17}$/
        return !value || pattern.test(value) || `${i18n.t('Chassi fora do padrão. Deve conter 17 dígitos alfanumérico, em maiúsculo.')}`
    },
    motor: value => {
        const pattern = /^[0-9A-Z]{9,19}$/
        return !value || pattern.test(value) || `${i18n.t('Motor fora do padrão. Deve conter 9 a 19 dígitos alfanumérico, em maiúsculo.')}`
    },
    odometer: value => {
        const pattern = /^[0-9]{1,6}.?,?[0-9]{0,3}$/
        return !value || pattern.test(value) || `${i18n.t('Odometer deve ser entre 1 e 999999. Máximo de 3 casas decimais')}`
    },
    urlName: value => {
        const pattern = /^[a-z0-9_-]*$/
        return !value || pattern.test(value) || `${i18n.t('Deve conter apenas dígitos alfanumérico, underline ou traço.')}`
    }
};